// import React from "react"
// import Layout from "../components/layout"
// import { Helmet } from "react-helmet";

import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Helmet } from "react-helmet";
import "../components/portfolio.css"
import Img from "gatsby-image"
import { Link } from "gatsby"

const Work = ({data}) => {

  const { edges } = data.allMarkdownRemark

  let portfolio = [];
  let archive = [];
  edges.forEach((edge) => {
    if (edge.node.frontmatter.is_portfolio === "yes") {
        portfolio.push(edge);
      }
    else archive.push(edge);
    })

    portfolio = portfolio.map((edge) => {
    const {frontmatter} = edge.node
    const taglength = frontmatter.tags.length
    return (
      <div>
      <a href={frontmatter.slug} style={{textDecoration:"none", width:"auto", height:"auto"}}>
      <div className="feature" style={{height:"500", display:"block"}}key={frontmatter.slug}>
      { !!frontmatter.featureImage && !!frontmatter.featureImage.childImageSharp
        ? <Img fluid={frontmatter.featureImage.childImageSharp.fluid}
               alt={frontmatter.title}
          />
        : <img src={frontmatter.featureImage.publicURL}
               alt={frontmatter.title}
         />
      }
      <p className="overlay">
        {
          frontmatter.tags.map((tag,index) =>
          {
            return (
              <span>
              <Link className="tag-link" to={`tags/${tag}`}>
              {tag}
              </Link>
              { (index < taglength-1) ? ', ' : '' }
              </span>
            )
          })
        }
      </p>
      <h3 className="work-title">{frontmatter.title}</h3>
      </div>
      </a>
      </div>
    )
  })

  archive = archive.map((edge) => {
    const {frontmatter} = edge.node
    const taglength = frontmatter.tags.length
    return (
      <div>
      <a href={frontmatter.slug} style={{textDecoration:"none", width:"auto", height:"auto"}}>
      <div className="feature" style={{height:"500", display:"block"}}key={frontmatter.slug}>
      { !!frontmatter.featureImage && !!frontmatter.featureImage.childImageSharp
        ? <Img fluid={frontmatter.featureImage.childImageSharp.fluid}
               alt={frontmatter.title}
          />
        : <img src={frontmatter.featureImage.publicURL}
               alt={frontmatter.title}
         />
      }
      <p className="overlay">
        {
          frontmatter.tags.map((tag,index) =>
          {
            return (
              <span>
              <Link className="tag-link" to={`tags/${tag}`}>
              {tag}
              </Link>
              { (index < taglength-1) ? ', ' : '' }
              </span>
            )
          })
        }
      </p>
      <h3 className="work-title">{frontmatter.title}</h3>
      </div>
      </a>
      </div>
    )
  })

  // console.log(edges)
  return (
    <Layout>

      <Helmet>
        <title> Work </title>
      </Helmet>

    <div>
        <div>
        <h1>Work</h1>
        <p>
          Here is an index of some selected projects of mine.
          <br/><br/>
        </p>
        </div>
    </div>

    <div className="grid">
    {portfolio}
    </div>

    <div>
        <div>
        <h1>Archive</h1>
        <p>
          Here is an archive of older projects, that will hopefully grow.
          <br/><br/>
        </p>
        </div>
    </div>

    <div className="col3">
    {archive}
    </div>

    </Layout>
  )
}


export const query = graphql`
query WorkQuery {
  allMarkdownRemark(
    filter: {frontmatter: {is_portfolio: {ne: null}}},
    sort: {order: ASC, fields: frontmatter___order})
    {
      edges {
        node {
          frontmatter {
            description
            is_portfolio
            slug
            tags
            title
              featureImage {
                childImageSharp {
                      fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
                publicURL
          }
        }
      }
    }
  }
}
`

export default Work
